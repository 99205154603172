import { useEffect } from "react";
import {
  Grid,
  useTheme,
  useMediaQuery,
  Box,
  Typography,
  Link,
} from "@mui/material";

import routes from "../infrastructure/navigation/routes";
import Header from "../infrastructure/layout/components/header.component";
import AppleDownloadIcon from "../fixtures/icons/apple-download.icon";

function HomeScreen() {
  const theme = useTheme();
  const belowBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    localStorage.clear();
  });

  return (
    <>
      <Header />
      <Grid
        container
        sx={{
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          md={10}
          sx={{
            overflow: "hidden",
            marginTop: "-72px",
            marginBottom: belowBreakPoint ? "0px" : "72px",
            borderRadius: { md: "0px 0px 50px 50px" },
            position: "relative",
          }}
        >
          {belowBreakPoint ? (
            <>
              <Box
                component="img"
                sx={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                }}
                alt="Rectangle"
                src="images/home/mobile-main-banner.webp"
              />

              <Box
                sx={{
                  position: "absolute",
                  bottom: "12%",
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                }}
              >
                <Link
                  sx={{ cursor: "pointer" }}
                  target="_blank"
                  href="https://apps.apple.com/us/app/ai-headshot-generator-dapper/id6476905877"
                >
                  <Box sx={{ textAlign: "center" }}>
                    <AppleDownloadIcon />
                  </Box>
                </Link>
                <Link sx={{ cursor: "pointer" }} href={routes.SIGN_UP}>
                  <Box
                    sx={{
                      textAlign: "center",
                      marginTop: "64px",
                    }}
                  >
                    <Typography
                      variant="banner"
                      sx={{
                        color: "#FFF",
                        textDecoration: "underline",
                        textAlign: "center",
                      }}
                    >
                      Try on web
                    </Typography>
                  </Box>
                </Link>
                <Box
                  sx={{
                    marginTop: "64px",
                    width: "300px",
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <Typography
                      sx={{
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Bai Jamjuree",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                      }}
                    >
                      By logging in, you agree to our:
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Bai Jamjuree",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                      }}
                    >
                      <Link
                        sx={{ cursor: "pointer" }}
                        target="_blank"
                        href={routes.TERMS}
                      >
                        <span style={{ textDecoration: "underline" }}>
                          Terms of Service
                        </span>{" "}
                      </Link>
                      and{" "}
                      <Link
                        sx={{ cursor: "pointer" }}
                        target="_blank"
                        href={routes.PRIVACY}
                      >
                        <span style={{ textDecoration: "underline" }}>
                          Privacy Policy
                        </span>
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                component="img"
                sx={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                }}
                alt="Rectangle"
                src="images/home/main-banner.webp"
              />

              <Box
                sx={{
                  position: "absolute",
                  bottom: 40,
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                }}
              >
                <Link
                  sx={{ cursor: "pointer" }}
                  target="_blank"
                  href="https://apps.apple.com/us/app/ai-headshot-generator-dapper/id6476905877"
                >
                  <Box sx={{ textAlign: "center" }}>
                    <AppleDownloadIcon />
                  </Box>
                </Link>
                <Box sx={{ marginTop: { md: "16px", xl: "40px" } }}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Bai Jamjuree",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                      }}
                    >
                      By logging in, you agree to our:
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      color: "#FFF",
                      textAlign: "right",
                      fontFamily: "Bai Jamjuree",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "24px",
                    }}
                  >
                    <Link
                      sx={{ cursor: "pointer" }}
                      target="_blank"
                      href={routes.TERMS}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        Terms of Service
                      </span>
                    </Link>{" "}
                    and{" "}
                    <Link
                      sx={{ cursor: "pointer" }}
                      target="_blank"
                      href={routes.PRIVACY}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        Privacy Policy
                      </span>
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default HomeScreen;
